<template>
	<div class="friendCircle page">
		<div class="p-footer h-end"
		     style="position: relative;">
			<button class="btn auto fill ml-3"
			        @click="toIndex()">返回首页
			</button>
		</div>
		<div class="s1 p-card fs-16 m-3">
			<div class="h-between p-3 text">
				<div class="flex">
					<p>自动跟发官方朋友圈</p>
					<input v-model="status"
					       type="checkbox"
					       class="switch"
					       @change="statusChange">
				</div>
				<span>发布后海报会自动转码</span>
				<div class="fs-12 color-gray flex">新设备上发朋友圈时，微信有可能发送不成功 <u class="fs-12 color-gray flex flex-right">
					<img src="../../assets/i-orange.png"
					     class="i_remind"/>微信提示</u></div>
			</div>
			<card :fillet="false" is-link link-color="#999999" @click="$router.push('/friendCirclePermission')">
				<template #title>
					<span :class="permissionData.color"> {{ permissionData.title }}</span>
				</template>
				<template #header-right>
					<span class="mr-1  fs-12 text-flow tag-show"
					      :class="permissionData.color ? permissionData.color : 'color-gray'">
						{{ permissionData.tag }}
					</span>
				</template>
				<div class="fs-12 color-gray mt-1">通过自动跟发的朋友圈，可设置可见人群</div>
			</card>
		</div>
		<div class="s2 m-3">
			<p class="title">朋友圈列表</p>
			<div class="p-card fs-16">
				<div class="b2">
					<van-tabs v-model="tabVal">
						<van-tab :title="`待发送（${waitListTotal}）`">
							<template v-if="waitList.length>0">
								<van-list v-model:loading="waitLoading"
								          :finished="waitFinished"
								          finished-text="没有更多了"
								          @load="nextWaitList">
									<div v-for="(item, index) in waitList"
									     :key="index"
									     class="item">
										<img class="headImg" :src="robotInfo.headImgUrl" alt="">
										<div class="text">
											<div class="name">
												<p>{{ robotInfo.nickName }}</p>
												<template v-if="!item.source">
													<div class="btn" @click="goEdit(item.ucId, 1)">编辑</div>
													<div class="btn" @click="setWaitStatus(item, 1, index)">删除</div>
												</template>
												<van-switch v-if="item.source"
												            v-model="item.followState"
												            size="14px"
												            @change="setWaitStatus(item, 0)"/>
											</div>
											<show-explain :content="item.title "/>
											<div v-if="item.releaseType === 2005"
											     class="link">
												<div class="linkImg">
													<van-image :src="item.linkImg"
													           fit="cover" lazy-load
													           @click="getImg(item.linkImg)"/>
													<!-- <img :src="item.linkImg" alt=""> -->
												</div>
												<div class="linkTitle two-flow">{{ item.linkTitle }}</div>

											</div>
											<div class="video mb-2" v-else-if="item.releaseType===2004" @click.capture="playVideo(item)">
												<img :src="item.videoUrl+'?x-oss-process=video/snapshot,t_7000,f_jpg,w_160,h_90,m_fast'"
												     class="upload_img"/>
												<img src="../../assets/icon-play.png"
												     class="reply_list_play"/>
												<video v-show="item.isPlay"
												       class="video-play"
												       controls=""
												       @error="videoError(msgData)"
												       @ended="item.isPlay = false"
												       :ref="(el) => { item.video = el}"
												       :src="item.videoUrl"></video>
											</div>
											<van-grid v-if="item.imgs.length > 0"
											          :column-num="3"
											          :border="false">
												<van-grid-item v-for="(src, index) in item.imgs"
												               :key="index">
													<van-image :src="src" lazy-load
													           fit="scale-down"
													           @click="getImgs(item.imgs, index)"/>
												</van-grid-item>
											</van-grid>
											<wx-comment :name="robotInfo.nickName" v-if="item.comments?.length > 0" :comment="item.comments"/>
											<div class="time">
												<p>发送时间：{{ item.pushDateTime }}</p>
												<div class="btn"
												     v-if="item.source">来自官方
												</div>
											</div>
										</div>
									</div>
								</van-list>
							</template>
							<no-data v-else :showButton="false"
							         title="暂未发布朋友圈"/>
						</van-tab>
						<van-tab :title="`已发送（${completeListTotal}）`">
							<template v-if="completeList.length>0">
								<van-list
										v-model:loading="completeLoading"
										:finished="completeFinished"
										finished-text="没有更多了"
										@load="nextCompleteList">
									<div v-for="(item, index) in completeList"
									     :key="index"
									     class="item">
										<img class="headImg" :src="robotInfo.headImgUrl" alt="">
										<div class="text">
											<div class="name flex">
												<p>{{ robotInfo.nickName }}</p>
												<div class="flex-right fs-12" :class="item.stateObj.color">
													{{ item.stateObj.name }}
												</div>
											</div>
											<!-- <div class="con two-flow">{{item.title}}</div> -->
											<show-explain :content="item.title"/>
											<div v-if="item.releaseType === 2005"
											     class="link">
												<div class="linkImg">
													<van-image :src="item.linkImg"
													           fit="cover" lazy-load
													           @click="getImg(item.linkImg)"/>
													<!-- <img :src="item.linkImg" alt=""> -->
												</div>
												<div class="linkTitle two-flow">{{ item.linkTitle }}</div>
											</div>
											<div class="video mb-2" v-else-if="item.releaseType===2004" @click.capture="playVideo(item)">
												<img :src="item.videoUrl+'?x-oss-process=video/snapshot,t_7000,f_jpg,w_160,h_90,m_fast'"
												     class="upload_img"/>
												<img src="../../assets/icon-play.png"
												     class="reply_list_play"/>
												<video v-show="item.isPlay"
												       class="video-play"
												       controls=""
												       @error="videoError(msgData)"
												       @ended="item.isPlay = false"
												       :ref="(el) => { item.video = el}"
												       :src="item.videoUrl"></video>
											</div>
											<van-grid v-if="item.imgs.length > 0"
											          :column-num="3"
											          :border="false">
												<van-grid-item v-for="(src, index) in item.imgs"
												               :key="index">
													<van-image :src="src" lazy-load
													           fit="scale-down"
													           @click="getImgs(item.imgs, index)"/>
												</van-grid-item>
											</van-grid>
											<wx-comment :name="robotInfo.nickName" v-if="item.comments?.length > 0" :comment="item.comments"/>
											<div class="time">
												<p>发送时间：{{ item.pushDateTime }}</p>
												<div class="btn"
												     @click="handleRemovePushed(item, index)">删除
												</div>
											</div>
										</div>
									</div>
								</van-list>
							</template>
							<no-data v-else no-button :showButton="false"
							         title="暂未发布朋友圈"/>
						</van-tab>
					</van-tabs>
				</div>
			</div>
		</div>
		<div class="p-footer h-end">
			<div class="flex  flex-right">
				<button class="btn auto fill ml-3"
				        @click="goEdit(0)">新增朋友圈
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import { ImagePreview } from 'vant'
import {
	mapGetters
} from 'vuex'
import { defineAsyncComponent } from 'vue'
import {
	ApiGetUserFollowSet,
	ApiGetWaitPushFriendCircleFollowMsgPage, ApiSentPushFriendMsgDel,
	ApiSentPushFriendMsgPage, ApiUserFriendCircleFollowSet, ApiWaitPushRobotFollowSet
} from '@/apis/UserFriendCircle'

function initWaitParams() {
	return {
		pageIndex: 1,
		pageSize: 10,
		robotWxId: '',
		sortOrder: 0,
		sortName: ''
	}
}

function initCompleteParams() {
	return {
		pageIndex: 1,
		pageSize: 10,
		robotWxId: '',
		sortOrder: 0,
		sortName: ''
	}
}

export default {
	name: 'WelcomeWords',
	components: {
		msgBox,
		WxComment: defineAsyncComponent(() => import('../../components/WxComment')),
		showExplain: defineAsyncComponent(() => import('./TheShowExplain')),
		Card: defineAsyncComponent(() => import('../../components/Card'))
	},
	data() {
		return {
			// 权限数据
			permissionData: {},
			status: false,
			waitParams: initWaitParams(),
			waitList: [],
			waitListTotal: 0,
			waitLoading: false,
			waitFinished: false,
			completeParams: initCompleteParams(),
			completeList: [],
			completeListTotal: 0,
			completeLoading: false,
			completeFinished: false,
			tabVal: 1
		}
	},
	computed: {
		...mapGetters(['robotInfo'])
	},
	created() {

	},
	mounted() {
		this.GetUserFollowSet()
		this.initWaitList()
		this.initCompleteList()
	},
	methods: {
		getImg(img) {
			ImagePreview({
				images: [img], // 预览图片的那个数组
				showIndex: false,
				loop: false,
				startPosition: 1 // 指明预览第几张图
			})
		},
		getImgs(imgs, index) {
			ImagePreview({
				images: imgs, // 预览图片的那个数组
				showIndex: true,
				loop: false,
				startPosition: index // 指明预览第几张图
			})
		},
		async GetUserFollowSet() {
			try {
				const res = await ApiGetUserFollowSet()
				this.status = res.followState
				const tag = res.privateTageList ? res.privateTageList.map(x => x.tagName)
				                                     .join() : ''
				const typeData = {
					0: {
						title: '谁可以看',
						color: '',
						tag: '所有人可见'
					},
					1: {
						title: '谁不可看',
						color: 'color-primary',
						tag: tag || ''
					},
					2: {
						title: '谁可以看',
						color: 'color-success',
						tag: tag || ''
					}
				}
				// 可见类型 0：默认无限制 1：指定可见 2：指定不可见
				this.permissionData = typeData[res.privateType ? res.privateType : 0]
			} catch (e) {
				console.warn(e)
			}
		},
		initWaitList() {
			this.waitParams = initWaitParams()
			this.waitParams.robotWxId = this.robotInfo.robotWxId
			this.waitList = []
			this.waitListTotal = 0
			this.getWaitList()
		},
		nextWaitList() {
			this.waitLoading = true
			this.waitParams.pageIndex++
			this.getWaitList()
		},
		async getWaitList() {
			try {
				const res = await ApiGetWaitPushFriendCircleFollowMsgPage(
						{ ...this.waitParams }
				)
				if (res) {
					this.waitList = [...this.waitList, ...res.dataList]
					this.waitListTotal = res.recordCount
					if (this.waitList.length >= this.waitListTotal) {
						this.waitFinished = true
					}
				}
			} catch (e) {
				console.warn(e)
			}
			this.waitLoading = false
		},
		initCompleteList() {
			this.completeParams = initWaitParams()
			this.completeParams.robotWxId = this.robotInfo.robotWxId
			this.completeList = []
			this.completeListTotal = 0
			this.getCompleteList()
		},
		nextCompleteList() {
			this.completeLoading = true
			this.completeParams.pageIndex++
			this.getCompleteList()
		},
		async getCompleteList() {
			try {
				const res = await ApiSentPushFriendMsgPage({ ...this.completeParams })
				if (res) {
					const stateType = {
						0: {
							name: '发送中',
							color: 'color-blue'
						},
						1: {
							name: '发送成功',
							color: 'color-success'
						},
						2: {
							name: '发送失败',
							color: 'color-primary'
						}
					}
					const data = res.dataList ? res.dataList.map(i => {
						i.stateObj = stateType[i.sendState] || {}
						return i
					}) : []
					this.completeList = [...this.completeList, ...data]
					this.completeListTotal = res.recordCount
					if (this.completeList.length >= this.completeListTotal) {
						this.completeFinished = true
					}
				}
			} catch (e) {
				console.warn(e)
			}
			this.completeLoading = false
		},
		async statusChange() {
			try {
				await ApiUserFriendCircleFollowSet(this.status)
				this.$Toast('切换成功')
			} catch (e) {
				console.warn(e)
			}
		},
		goEdit(n) {
			this.$router.push({
				path: '/friendCircleEdit',
				query: {
					isEdit: n
				}
			})
		},
		async setWaitStatus(item, type, index) {
			if (type) {
				this.$Dialog.confirm({
					    title: '确认删除这条朋友圈？'
				    })
				    .then(async () => {
					    await this.handleRemoveWaitPush(item, index)
				    })
			} else {
				await this.handleSetWaitPushState(item, index)

			}
		},
		// 待发送用户设置朋友圈跟发状态
		async handleSetWaitPushState(item, index) {
			try {
				const res = await ApiWaitPushRobotFollowSet({
					robotWxId: this.robotInfo.robotWxId,
					tId: item.tId,
					state: item.followState
				})
				if (res) {
					this.$Toast('设置成功')
					await this.GetUserFollowSet()
				} else {
					item.followState = !item.followState
				}
			} catch (e) {
				console.warn(e)
			}
		},
		// 待发送用户朋友圈删除状态
		async handleRemoveWaitPush(item, index) {
			try {
				const res = await ApiWaitPushRobotFollowSet({
					robotWxId: this.robotInfo.robotWxId,
					ucId: item.ucId,
					state: false
				})
				if (res) {
					// this.waitList = this.waitList.filter(obj => obj != item)
					this.waitList.splice(index, 1)
					this.waitListTotal = this.waitListTotal - 1
					this.$Toast('删除成功')
				}
			} catch (e) {
				console.warn(e)
			}
		},
		// 删除已发送朋友圈
		handleRemovePushed(item, index) {
			this.$Dialog.confirm({
				    title: '确认删除这条朋友圈？'
			    })
			    .then(async () => {
				    try {
					    const res = await ApiSentPushFriendMsgDel(item.rId)
					    if (res) {
						    this.completeList.splice(index, 1)
						    this.completeListTotal = this.completeListTotal - 1
						    this.$Toast('删除成功')
					    }
				    } catch (e) {
					    console.warn(e)
				    }
			    })
		},
		toIndex() {
			const _this = this
			_this.$router.replace('/index')
		},
		playVideo(item) {
			if (item.isVideoError && item.videoUrl) {
				this.$notify({
					type: 'warning',
					message: '本平台上暂不支持播放，不影响实际使用哦！'
				})
				return
			}
			const v = item.video
			item.isPlay = true
			v.play()
		},
		videoError(val) {
			if (!val.videoUrl) return
			val.isVideoError = true
			const v = val.video
			val.isPlay = false
			v.pause()
			this.$notify({
				type: 'warning',
				message: '本平台暂不支持播放，不影响实际使用哦！'
			})
		}
	}
}
</script>

<style scoped lang="scss">

.page {

	.s1 {
		.text {
			.flex {
				p {
					flex: 1;
					margin: 0;
					color: #333;
				}
			}

			span {
				font-size: px(12);
				color: #888;
			}
		}
	}

	.p-footer {
		padding-bottom: px(15);
	}

	.title {
		font-size: px(14);
		color: #888;
		margin: 0 0 px(5) 0;
	}

	.s2 {
		.b2 {
			padding-bottom: px(20);
		}

		.item {
			margin-left: px(10);
			padding: px(15) px(10) px(15) 0;
			border-bottom: px(1) #f1f1f1 dashed;
			font-size: px(14);
			display: flex;

			.headImg {
				width: px(44);
				height: px(44);
				overflow: hidden;
				border-radius: 50%;
				margin-right: px(6);

				img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}

			.text {
				flex: 1;

				.name {
					display: flex;
					margin-bottom: px(5);

					p {
						flex: 1;
						margin: 0;
						color: #35557c;
						font-size: px(14);
						line-height: px(20);
					}

					.btn {
						color: #35557c;
						font-size: px(12);
						margin-right: px(5);

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.con {
					margin-bottom: px(15);
				}

				.link {
					display: flex;
					align-items: center;
					background-color: #f6f6f6;
					border-radius: px(4);
					padding: px(7);
					margin-bottom: px(15);

					.linkImg {
						width: px(44);
						height: px(44);
						margin-right: px(5);
						border-radius: px(2);
						overflow: hidden;

						.van-image {
							width: 100%;
							height: 100%;
						}

						img {
							width: 100%;
							height: 100%;
							display: block;
						}
					}

					.linkTitle {
						flex: 1;
					}
				}

				.time {
					display: flex;
					align-items: center;

					p {
						font-size: px(12);
						color: #888;
						flex: 1;
						margin: 0;
					}

					.btn {
						color: #35557c;
						font-size: px(12);
					}
				}
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.van-grid {
		margin: px(10) 0;
	}
}

.video {
	position: relative;
	width: 100%;
	height: 200px;
	border-radius: 4px;

	.upload_img {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		object-fit: cover;
	}

	.reply_list_play {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 36px;
		height: 36px;
	}

	.video-play {
		border-radius: 8px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 10;
	}
}

</style>
<style lang="scss">
.friendCircle {
	padding-bottom: px(60);

	.van-grid-item__content {
		padding: 0;
	}

	.van-grid-item__content {
		position: relative;
		padding: px(4);

		.van-image {
			// width: px(80);
			// height: px(80);

			width: px(70);
			height: px(70);
		}
	}

	.mt-70 {
		margin-top: 70px !important;
	}

	.i_remind {
		width: px(16);
		height: px(16);
	}

}

@media (max-width: 370px) {
	.friendCircle {
		.van-grid-item__content {
			.van-image {
				width: px(60);
				height: px(60);
			}
		}
	}
}
</style>
